export default `<table>
  <tr>
    <td translate>Swiss grid (LV95)</td>
    <td>{{coord_2056|ngeoNumberCoordinates:0:'{x} / {y}'}}</td>
  </tr>
  <tr>
    <td translate>Swiss grid (LV03)</td>
    <td>{{coord_21781|ngeoNumberCoordinates:0:'{x} / {y}'}}</td>
  </tr>
  <tr>
    <td translate>Wgs Coord.</td>
    <td>{{coord_4326|ngeoNumberCoordinates:2:'{y} / {x}'}}</td>
  </tr>
  <tr>
    <td translate>Wgs Coord. DMS</td>
    <td>{{coord_4326|ngeoDMSCoordinates:0:'{y} {x}'}}</td>
  </tr>
  <tr>
    <td translate>Elevation (DTM2)</td>
    <td ng-if="DTM2">{{DTM2 | number}} m</td>
  </tr>
  <tr>
    <td translate>Elevation (DTMGR)</td>
    <td ng-if="DTMGR">{{DTMGR | number}} m</td>
  </tr>
</table>
<a
  ng-href="https://maps.google.ch/?ie=UTF8&ll={{coord_4326_northern}},{{coord_4326_eastern}}&layer=c&cbll={{coord_4326_northern}},{{coord_4326_eastern}}&cbp=12,57.78,,0,8.1"
  target="_blank"
  >Google StreetView</a
>`;
